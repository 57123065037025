body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}
html{
  font-size: 18px;
}
p,h1,h2,h3,h4,h5,h6,span,a, label, input{
  font-size: 18px;
  font-family: Work Sans;
}
.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  width: 300px;
  margin-bottom: 40px;
}
.need{
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
  font-weight: 700;
  color: black;
}
.odpoved{
  color: rgba(0, 0, 0,0.6);
}
.odpoved::before{
  content:"";
  width: 25%;
  height: 1px;

  background: rgba(0, 0, 0,0.2);
  position: relative;
  /* left: 0; */
  display: inline-block;
  top: -5px;
  margin-right: 10px;
}
.odpoved::after{
  content:"";
  width: 25%;
  height: 1px;
  background: rgba(0, 0, 0,0.2);
  position: relative;
  /* left: 0; */
  display: inline-block;
  top: -5px;
  margin-left: 10px;

}
.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.centerize{
  margin: 0 auto;
  text-align: left;
}
.form-group.radio{
  display: inline-block;

}
.center{
  text-align: center;
}
.btn.btn-primary{
  margin: 0 auto!important;
 margin-left: 20px;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 700;
  border: 0!important;
  color: black;


}
form{
  display: block;
}
.centerize form{
  display: inline-block;
}
.ans{
  margin-top: 20px;
  text-align: center;
  margin-bottom: 0px;
  color: black;
}
.ans2{
  margin-top: 0;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  color: black;
}
.otazka{
  font-size: 1.5em;
  color: black;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}
.nadpis.center{
  font-weight: 700;
  text-transform: uppercase;
  color: #5e9dd7;
  font-size: 2em;
}
.fa-angle-left{
  margin-right: 10px;
}
.rad{
  display: inline-block;
  width: 80%;
  padding-left: 15px;
  position: relative;
  color: black;
  font-size: 28px;
  font-weight: 700;
  top: -5px;
}
input[type='radio']{
  width: 25px;
  height: 25px;
}

.topka{
  margin-top: 20px;
}
.blackize{
  font-size: 60px;
  color: black;
}
.blueize{
  font-size: 60px;
}
.nadpis.center{
  margin-bottom: 50px;
}
.btn.btn-primary{
  display: block;
  margin-top: 20px;
  width: 100%;
  margin-left: 0;
  background-color: transparent;
  background-color: initial;
  border: 2px solid white;
  border-radius: 30px;
}
.edit{
  color: black;
  display: block;
}
.edit:hover{
  cursor: pointer;
  text-decoration: underline;
  color: black;
}
.underline{
  border-bottom: 4px solid #5e9dd7;;
}
*:focus{
  outline: medium none invert;
  outline: initial;
}
.row{
  min-width: 140px;
}
.btn.btn-primary:hover,.btn.btn-primary:active {
  display: block;
  margin-top: 20px;
  border: 0;
  box-shadow: none;
  box-shadow: initial;
  width: 100%;
  margin-left: 0;


  color:black;
  text-decoration: underline;
  background-color: white;
}
.btn.btn-primary:after{
  content: "\F105";
  font-family: "Font Awesome 5 Free";
  color: black;
  display: inline-block;
}
.App-link {
  color: #61dafb;
}
#root{
  background-color: white;
}

.ods{
  margin-top: 40px!important;
}
.btn.btn-primary.ods{
  width: 240px!important;
}
.dva{
  margin-bottom: 40px;
}
@media screen and (max-width: 500px){
  .blackize, .blueize{
    font-size: 45px;
  }
}
